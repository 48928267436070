import * as React from "react";
import styled, { keyframes, css } from "styled-components";
import { graphql } from "gatsby";

import Game from "../components/game";
import Scoreboard from "../components/scoreboard";
import { useRef } from "react";
import { useState } from "react";

export interface Game {
  name: string;
  isFinished: boolean;
  result: Clown[];
}

export interface Clown {
  name: string;
  points: number;
}

interface GameList {
  id: string;
  gameList: Game[];
}
interface QueryData {
  data: {
    allSanityGames: { nodes: GameList[] };
    allSanityClown: { nodes: Clown[] };
  };
}

const LandingSection = styled.section`
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Arial, Helvetica, sans-serif;

  > h1 {
    text-align: center;
    font-family: "Faster One";
    font-weight: normal;
    text-transform: uppercase;
  }

  > audio {
    visibility: hidden;
  }

  button {
    font-size: 72px;
    font-weight: bold;
    border: 0;
    background: none;
    cursor: pointer;
    border: 5px solid #7d0169;
    border-radius: 50%;
    height: 400px;
    width: 400px;
    color: #7d0169;

    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Impact, Haettenschweiler, "Arial Narrow Bold", sans-serif;

    &:hover {
      background: #7d0169;
      color: #fff;
    }
  }
`;

const visibleImageAnimation = keyframes`
    0% {
      z-index: -1;
    }
    50% {
      z-index: 10;
      
    }
    100% {
      z-index: -1;
    }
`;

const johanDance = keyframes`
    0% {
      transform: translateX(0) rotateY(0deg);
    }
    50% {
      transform: translateX(80vw)
    }
    51% {
      transform: translateX(80vw) rotateY(180deg);
    }
    100% {
      transform: translateX(0) rotateY(180deg);
    }
`;

const twinDance = keyframes`
    0% {
      transform: translateX(0) rotateY(180deg);
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    50% {
      transform: translateX(-80vw) rotateY(180deg);
    }
    51% {
      transform: translateX(-80vw) rotateY(0deg);
    }
    100% {
      transform: translateX(0) rotateY(0deg);
      opacity: 1;
    }
`;

const robinDance = keyframes`
    0% {
      transform: translateX(0) rotateY(0deg);
      opacity: 0;
    }
    1% {
      transform: translateX(0) rotateY(0deg);
      opacity: 1;
    }
    50% {
      transform: translateX(10vw);
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
`;

const linusDance = keyframes`
    0% {
      transform: translateX(0) rotateY(180deg);
      opacity: 0;
    }
    1% {
      opacity: 1;
    }
    50% {
      transform: translateX(-10vw) rotateX(180deg);
    }
    50% {
      transform: translateX(-10vw) rotateX(0);
    }
    100% {
      transform: translateX(0) rotateX(0deg);
      opacity: 1;
    }
`;

const GameSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  font-family: Arial, Helvetica, sans-serif;
  margin-bottom: 300px;

  h2 {
    text-decoration: underline;
    text-transform: uppercase;
  }

  > div {
    margin-right: 20px;
    margin-bottom: 20px;
    width: 29%;

    line-height: 1.5;

    padding: 10px;

    @media (max-width: 768px) {
      width: 100%;
      margin-right: 0;
    }

    > div {
      &:nth-child(even) {
        background: #f2eded;
      }
    }
  }

  ul {
    margin-block-start: 0em;
    margin-block-end: 0em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 0px;

    list-style: none;

    > li {
      &:nth-child(odd) {
        background: #f2eded;
      }
    }
  }
`;

const DanceWrapper = styled.div<{ startedIntro: boolean }>`
  display: none;
  position: absolute;

  ${(props) =>
    props.startedIntro &&
    css`
      animation-iteration-count: infinite;
      animation-duration: 12000ms;
      display: block;

      &.johan {
        animation-name: ${johanDance};
        top: 40vh;
        left: 0;
        width: 200px;
      }

      &.robin {
        opacity: 0;
        animation-name: ${robinDance};
        animation-delay: 3000ms;
        top: 20px;
        left: 20px;
        width: 200px;
      }

      &.linus {
        opacity: 0;
        animation-name: ${linusDance};
        animation-delay: 7000ms;
        top: 20px;
        right: 20px;
        width: 150px;
      }

      &.twins {
        opacity: 0;
        animation-name: ${twinDance};
        animation-delay: 10000ms;
        top: 60vh;
        right: 0;
        width: 350px;

        > img {
          background: none;
        }
      }
    `};

  > img {
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &:last-of-type {
      z-index: -1;
      animation-name: ${visibleImageAnimation};
      animation-duration: 1000ms;
      animation-iteration-count: infinite;

      .robin & {
        animation-duration: 2000ms;
      }
    }
  }
`;

const IndexPage = ({ data }: QueryData) => {
  const [startedIntro, setStartedIntro] = useState(false);
  const games = data?.allSanityGames?.nodes[0].gameList;
  const clowns = data?.allSanityClown?.nodes;

  const finishedGames = games.filter((game) => {
    return game.isFinished;
  });

  const upcomingGames = games.filter((game) => {
    return !game.isFinished;
  });

  const audioRef = useRef();

  return (
    <main>
      <LandingSection>
        <audio loop ref={audioRef}>
          <source src="/sippin.m4a" type="audio/mp4" />
          Your browser does not support the audio element.
        </audio>

        <button
          onClick={(event) => {
            audioRef?.current?.play();
            event.target.style.display = "none";
            setStartedIntro(true);
          }}
        >
          CLOWN TIME
        </button>

        <DanceWrapper className="johan" startedIntro={startedIntro}>
          <img src="/johan.png"></img>
          <img src="/johan2.png"></img>
        </DanceWrapper>

        <DanceWrapper className="robin" startedIntro={startedIntro}>
          <img src="/robin.png"></img>
          <img src="/robin2.png"></img>
        </DanceWrapper>

        <DanceWrapper className="linus" startedIntro={startedIntro}>
          <img src="/linus2.png"></img>
          <img src="/linus.png"></img>
        </DanceWrapper>

        <DanceWrapper className="twins" startedIntro={startedIntro}>
          <img src="/twins.png"></img>
        </DanceWrapper>
      </LandingSection>

      <GameSection>
        <div>
          <h2>Kommande spel</h2>
          {upcomingGames.map((game, index) => {
            return <Game game={game} key={`upcoming-${index}`} />;
          })}
        </div>

        <div>
          <h2>Avslutade spel</h2>
          {finishedGames.map((game, index) => {
            return <Game game={game} key={`finished-${index}`} />;
          })}
        </div>

        <div>
          <h2>Poängställning</h2>
          <Scoreboard games={finishedGames} clowns={clowns} />
        </div>
      </GameSection>
    </main>
  );
};

export default IndexPage;

export const query = graphql`
  query GBGQuery {
    allSanityGames(filter: { title: { eq: "Game List GBG" } }) {
      nodes {
        id
        gameList {
          name
          isFinished
          result {
            name
          }
        }
      }
    }
    allSanityClown {
      nodes {
        name
        points
      }
    }
  }
`;
